import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import localforage from "localforage";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
    key: "root",
    whitelist: ["hubs", "carriers", "tags", "service_levels", "session"],
    storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configStore = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export default configStore;

export const store = configStore;

export const persistedStore = persistStore(store);
