import {
    SET_GENERAL_DATA,
    SET_SALES_AGENTS,
    SET_ENTERPRISE_TYPES,
    SET_RATE_GROUPS,
    SET_SETTINGS
} from "../types/types";

const initialState = {
    general_data: null,
    enterprise_types: [],
    sales_agents: [],
    rate_groups: [],
    settings: null,
};

const generalDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GENERAL_DATA:
            return { ...state, general_data: action.payload };
        case SET_ENTERPRISE_TYPES:
            return { ...state, enterprise_types: action.payload };
        case SET_SALES_AGENTS:
            return { ...state, sales_agents: action.payload };
        case SET_RATE_GROUPS:
            return { ...state, rate_groups: action.payload };
        case SET_SETTINGS:
            return { ...state, settings: action.payload };
        default:
            return state;
    }
};

export default generalDataReducer;
