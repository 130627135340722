import { combineReducers } from "redux";
import authReducer from "./authReducers";
import mapsReducer from "./mapsReducer";
import branchesReducer from "./branchesReducer";
import tagsReducer from "./tagsReducer";
import generalDataReducer from "./generalDataReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    maps: mapsReducer,
    general_data: generalDataReducer,
    hubs: branchesReducer,
    tags: tagsReducer
});

export default rootReducer;
