import {
  SET_BRANCHES
} from "../types/types";

const initialState = {
  hubs: []
};

const branchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BRANCHES:
      return { ...state, hubs: action.payload };
    default:
      return state;
  }
};

export default branchesReducer;
