import React from 'react';
import { get_general_data_filters } from 'infra/services/api';

const GeneralDataLogic = () => {
    return {
        get_general_data: async () => {
            const response = await get_general_data_filters();
            return response;
        }
    }
};

export default GeneralDataLogic;