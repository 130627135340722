import React from 'react';
import {
    Dialog as MuiDialog,
    DialogContent,
    DialogTitle,
    IconButton,
    DialogActions,
    Button,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import colors from 'app/assets/colors/colors';

const myStyle = {
    background: '#264796',
    borderRadius: '5px',
};

const StyledMuiDialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const StyledDialogTitle = (props) => {
    const { title, titleJSX, onClose, ...other } = props;
    return (
        <DialogTitle
            sx={{
                m: 0,
                p: 2,
                display: 'flex',
                alignItems: 'center',
                fontWeight: "bold",
                color: "#fff",
                ...myStyle
            }}
            {...other}
        >
            {titleJSX ? titleJSX : title}
            {onClose && !titleJSX ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        background: colors.TCG_WHITE,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const StyledDialogActions = (props) => {
    const { children, ...other } = props;
    return (
        <DialogActions
            sx={{
                m: 0,
                p: 2,
                background: colors.TCG_WHITE,
                justifyContent: 'space-between',
            }}
            {...other}
        >
            {children}
        </DialogActions>
    );
};

const Dialog = ({
    backDropClose,
    showTitle,
    actionJsx,
    showActions,
    title,
    children,
    onClose,
    open,
    titleJSX,
    ...rests
}) => {
    return (
        <React.Fragment>
            <StyledMuiDialog {...rests} open={open} onClose={() => backDropClose ? onClose() : null} >
                {showTitle && (
                    <StyledDialogTitle
                        id="customized-dialog-title"
                        onClose={onClose}
                        titleJSX={titleJSX}
                        title={title}
                    />
                )}
                <DialogContent>{children}</DialogContent>
                <Divider />
                {showActions && (
                    <StyledDialogActions>{actionJsx}</StyledDialogActions>
                )}
            </StyledMuiDialog>
        </React.Fragment>
    );
};

export default Dialog;

Dialog.defaultProps = {
    showTitle: true,
    title: 'Modal Title',
    titleJSX: null,
    showActions: true,
    actionJsx: <Button> Save</Button>,
    fullWidth: true,
    onClose: () => { },
    backDropClose: true,
    maxWidth: 'md' || 'xs' || 'sm' || 'xl',
    sx: {
        '.MuiDialog-paper': {
            position: 'absolute',
            top: 0,
            borderRadius: '5px',
        },
    },
};
