import { API_URL } from 'config.js';
import { get, upload, post } from './helpers';

export const getSettings = async (id) => {
    const response = await get(`${API_URL}/settings/${id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getApiKeys = async (id) => {
    const response = await get(`${API_URL}/fetch-api-key?account_id=${id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const createNewApiKey = async (payload) => {
    const response = await post(`${API_URL}/users/generate-api-key`, payload);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const deleteApiKey = async (payload) => {
    const response = await post(`${API_URL}/users/delete-api-key`, payload);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const quoteGetSettings = async (id) => {
    const response = await get(`${API_URL}/settings/get-settings/${id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const uploadLogo = async (payload) => {
    const response = await upload(`${API_URL}/uploadLogo`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};
