import React, { createContext, useContext } from 'react';
import { MatxLoading } from 'app/components';
import { getSettings } from 'infra/services/api';
import { useSnackbar } from 'notistack';
import { useCreate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { setSettings } from 'core/redux/actions/generalDataActions';
import SettingsLogic from 'core/logic/SettingsLogic';

const SettingsContext = createContext({
    createSetting: () => { },
    refetch: () => { },
    setOpen: () => { },
    data: null,
    isLoading: false,
    open: false
});

export const AccountSettingsProvider = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();
    const _settings = SettingsLogic();
    const dispatch = useDispatch();
    const [create, { isLoading: submitLoading }] = useCreate();
    const [isLoading, setisLoading] = React.useState(false);
    const [fetching_settings, set_fetching_settings] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [data, setdata] = React.useState(null);
    const account_id = localStorage.getItem('account_id');


    React.useEffect(() => {
        if (account_id) {
            const getSettings = async () => {
                set_fetching_settings(true);
                try {
                    const response = await _settings.getSettings(account_id);
                    if (response) {
                        formatData(response);
                    }
                } catch (error) {
                    throw error;
                } finally {
                    set_fetching_settings(false);
                }
            };
            getSettings();
        }
    }, [account_id]);

    const createSetting = async (post_data) => {
        await create(
            'settings',
            {
                data: post_data,
            },
            { onSettled: onSuccess },
            { onError: onError }
        );
    };

    const uploadLogo = async (formdata) => {
        try {
            const response = await _settings.uploadLogo(formdata);
            if (response.status) {
                refetch();
                enqueueSnackbar('Uploaded new logo!', { variant: 'success' });
            } else {
                setOpen(false);
                enqueueSnackbar('Error adding of logo', { variant: 'error' });
            }
        } catch (error) {
            throw error;
        }
    };

    const onSuccess = () => {
        enqueueSnackbar(`Settings saved`, {
            variant: 'success',
        });
        setOpen(false);
        refetch();
    };

    const onError = (error) => {
        setOpen(false);
        enqueueSnackbar(`${error.message}`, { variant: 'error' });
    };

    //custom for remove
    const removeMessage = async (post_data) => {
        await create(
            'settings',
            {
                data: post_data,
            },
            { onSettled: successRemove },
            { onError: onError }
        );
    };

    const successRemove = () => {
        enqueueSnackbar(`Successfully removed site wide message`, {
            variant: 'success',
        });
        setOpen(false);
        refetch();
    };

    const refetch = async () => {
        const response = await getSettings(account_id);
        formatData(response);
    };

    const formatData = (settings_data) => {
        const { provider_settings } = settings_data;
        if (Array.isArray(provider_settings)) {
            const json_data = {};
            
            provider_settings.forEach((element) => {
                let value = '';
                if (arrays.indexOf(element.setting) !== -1) {
                    value = JSON.parse(element.value);
                } else {
                    // skip true/false the integer text field
                    const skip_vals = ['send_email_after_failed_collection_attempt_after', 'send_email_after_failed_delivery_attempt_after'];
                    if(skip_vals.includes(element.setting)){
                        json_data[element.setting] = element.value;
                        return;
                    }

                    if (element.value === "1") {
                        value = true
                    } else if (element.value === "0") {
                        value = false;
                    } else {
                        value = element.value;
                    }
                }

                json_data[element.setting] = value;
            });

            setdata({ ...define_settings, ...json_data });
            dispatch(setSettings({ ...define_settings, ...json_data }));
        }
    };

    const getApiKeys = async (account_id) => {
        try {
            const reposne = await _settings.getApiKeys(account_id);
            return reposne
        } catch (error) {
            throw error;
        }
    }

    const createNewApiKey = async (payload) => {
        try {
            const reposne = await _settings.createNewApiKey(payload);
            return reposne
        } catch (error) {
            throw error;
        }
    }

    const deleteApiKey = async (payload) => {
        try {
            const reposne = await _settings.deleteApiKey(payload);
            return reposne
        } catch (error) {
            throw error;
        }
    }

    if (isLoading) {
        return <MatxLoading />;
    }

    return (
        <SettingsContext.Provider
            value={{
                data: data,
                open: open,
                isLoading: submitLoading,
                fetching_settings,
                setOpen: setOpen,
                refetch: refetch,
                createSetting: createSetting,
                uploadLogo: uploadLogo,
                removeMessage: removeMessage,
                onSuccess: onSuccess,
                onError: onError,
                getApiKeys,
                createNewApiKey,
                deleteApiKey
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsContext;

export const useSettings = () => useContext(SettingsContext);

export const arrays = [
    'webhook_tracking_url',
    'parcel_types',
    'dashboard_statuses_collected_and_undelivered',
    'dashboard_statuses_collected_and_undelivered',
    'dashboard_statuses_uncollected_and_undelivered',
    'exclude_features',
    'provider_tracking_prefixes',
    'permissions_additional_settings',
    'shipment_cancellation_reason',
    'carriers',
    'service_levels',
    'other_scans',
    'delivery_failed_attempt_reasons',
    'shipment_terms',
    'note_types',
    'rejection_reasons',
    'collection_failed_attempt_reasons',
    'delivery_failed_attempt_reasons',
    'integrations',
];


export const define_settings = {
    // NEW
    default_collection_time_from: "",
    default_collection_time_to: "",
    webhook_tracking_url: [],
    default_dropoff_locker: "",
    auto_save_new_address_book: false,
    notify_collection_on_ready_for_pickup_email: '',
    notify_delivery_on_ready_for_pickup_email: '',
    notify_delivery_on_shipment_address_changed_email: '',
    notify_collection_on_shipment_address_changed_email: '',
    notify_collection_in_locker: '',
    notify_delivery_in_locker: '',
    hide_collection_address_on_waybills_and_emails_to_the_delivery_contact: false,
    hide_account_name_on_sms_and_email_notifications: false,
    disable_billing_emails: false,
    send_email_after_failed_collection_attempt: false,
    send_email_after_failed_collection_attempt_after: 0,// int
    send_email_after_failed_delivery_attempt: 0,// int
    send_email_after_failed_delivery_attempt_after: 0,// int
    send_email_after_shipment_address_changed: false,// int
    // NEW
    url: '',
    terms_and_condition_url: '',
    privacy_url: '',
    tracking_url: '',
    logo_url: '',
    work_hours_from: '',
    work_hours_to: '',
    support_email_address: '',
    support_contact_number: '',
    dashboard_stats_start_date: '',
    dashboard_uncollected_shipments_duration_days: '',
    dashboard_undelivered_shipments_duration_days: '',
    dashboard_unclosed_collection_requests_duration_days: '',
    dashboard_statuses_collected_and_undelivered: [],
    dashboard_statuses_uncollected_and_undelivered: [],
    exclude_features: [],
    map_max_node_count: '',
    provider_tracking_prefixes: [],
    message_settings_type: '',
    client_portal_site_wide_message_type: '',
    client_portal_site_wide_message: '',
    admin_site_wide_message_type: '',
    admin_site_wide_message: '',
    shipment_terms: [],
    billing_email_address: '',
    billing_contact_number: '',
    invoice_company_name: '',
    invoice_company_address: '',
    invoice_company_registration_no: '',
    invoice_company_vat_no: '',
    invoice_vat_percentage: '',
    invoice_quote_disclaimer: '',
    invoice_trigger_shipment_status: '',
    shipping_difference_minimum: '',
    insurance_declared_value_enabled: '',
    insurance_max_declared_value: '',
    show_bill_transactions: '',
    payment_methods_payfast_merchantID: '',
    payment_methods_payfast_merchantKey: '',
    payment_methods_payfast_passphrase: '',
    payment_methods_payfast_enabled: '',
    payment_methods_eft_details_bank: '',
    payment_methods_eft_details_branch_code: '',
    payment_methods_eft_details_account_type: '',
    payment_methods_eft_details_account_number: '',
    payment_methods_eft_enabled: '',
    payment_methods_payfastTopup_merchantID: '',
    payment_methods_payfastTopup_merchantKey: '',
    payment_methods_payfastTopup_passphrase: '',
    payment_methods_payfastTopup_topup_limit: '',
    payment_methods_payfastTopup_enabled: '',
    payment_methods_bobpayTopup_recipient_account: '',
    payment_methods_bobpayTopup_passphrase: '',
    payment_methods_bobpayTopup_topup_limit: '',
    payment_methods_ozow_enabled: '',
    payment_methods_ozow_siteCode: '',
    payment_methods_ozowTopup_enabled: '',
    payment_methods_ozowTopup_siteCode: '',
    payment_methods_ozowTopup_topup_limit:'',
    payment_methods_peachTopup_merchantID: '',
    payment_methods_peachTopup_secretKey: '',
    payment_methods_peachTopup_limit: '',
    payment_methods_peach_payment_enabled: '',
    payment_import_start_date: '',
    payment_import_end_date: '',
    maximum_parcel_length: '',
    maximum_parcel_width: '',
    maximum_parcel_height: '',
    maximum_parcel_weight: '',
    parcel_types: [],
    note_types: [],
    permissions_additional_settings: [],
    shipment_cancellation_reason: [],
    carriers: [],
    service_levels: [],
    other_scans: [],
    driver_can_action_hub_statuses: '',
    driver_must_add_tracking_sticker_each_parcel: '',
    driver_can_reject_parcels_shipments: '',
    driver_can_cancel_parcels: '',
    driver_can_cancel_shipments: '',
    driver_must_scan_each_parcel: '',
    driver_can_add_shipments: '',
    driver_can_add_parcels: '',
    driver_show_floor_check_option: '',
    allow_push_notifications_weekend: '',
    allow_push_notifications_public_holidays: '',
    notification_schedule_from: '',
    notification_schedule_until: '',
    drivers_can_enter_onetime_pin: '',
    drivers_can_send_onetime_pin: '',
    drivers_can_use_fallback_option: '',
    driver_pod2_name_enabled: '',
    driver_pod2_mobile_number_enabled: '',
    driver_pod2_department_enabled: '',
    driver_pod2_description_enabled: '',
    driver_pod2_photos_enabled: '',
    driver_pod2_signature_enabled: '',
    driver_pod2_name_required: '',
    driver_pod2_mobile_number_required: '',
    driver_pod2_department_required: '',
    driver_pod2_description_required: '',
    driver_pod2_photos_required: '',
    driver_pod2_signature_required: '',
    rejection_reasons: [],
    collection_failed_attempt_reasons: [],
    delivery_failed_attempt_reasons: [],
    notification_email_address: '',
    notification_name: '',
    notification_footer: '',
    notify_collection_on_submitted_email: '',
    notify_collection_on_collected_email: '',
    notify_collection_on_at_destination_hub_email: '',
    notify_collection_on_out_for_delivery_email: '',
    notify_collection_on_collection_failed_attempt_email: '',
    notify_collection_on_delivery_failed_attempt_email: '',
    notify_collection_on_delivered_email: '',
    notify_collection_on_collection_exception_email: '',
    notify_collection_on_delivery_exception_email: '',
    notify_collection_on_cancelled_email: '',
    notify_delivery_on_collected_email: '',
    notify_delivery_on_at_destination_hub_email: '',
    notify_delivery_on_out_for_delivery_email: '',
    notify_delivery_on_delivery_failed_attempt_email: '',
    notify_delivery_on_delivered_email: '',
    notify_delivery_on_delivery_exception_email: '',
    notify_delivery_on_cancelled_email: '',
    notify_collection_on_submitted_whatsapp: '',
    notify_collection_on_collected_whatsapp: '',
    notify_collection_on_at_destination_hub_whatsapp: '',
    notify_collection_on_out_for_delivery_whatsapp: '',
    collection_on_ready_for_pickup_email_whatsapp: '',
    notify_collection_on_collection_failed_attempt_whatsapp: '',
    notify_collection_on_delivery_failed_attempt_whatsapp: '',
    notify_collection_on_delivered_whatsapp: '',
    notify_collection_on_collection_exception_whatsapp: '',
    notify_collection_on_delivery_exception_whatsapp: '',
    notify_collection_on_cancelled_whatsapp: '',
    notify_collection_on_shipment_address_changed_whatsapp: '',
    notify_delivery_on_collected_whatsapp: '',
    notify_delivery_on_at_destination_hub_whatsapp: '',
    notify_delivery_on_out_for_delivery_whatsapp: '',
    notify_delivery_on_delivery_failed_attempt_whatsapp: '',
    notify_delivery_on_delivered_whatsapp: '',
    notify_delivery_on_delivery_exception_whatsapp: '',
    notify_delivery_on_cancelled_whatsapp: '',
    shipment_rescheduled_notification_email: '',
    shipment_cancelled_notification_email: '',
    notify_shipment_rescheduled_email: '',
    notify_shipment_rescheduled_whatsapp: '',
    shorten_tracking_urls: '',
    integrations: [],
};
