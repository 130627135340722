import React from 'react';
import { Box } from '@mui/material';
import Lottie from 'lottie-react';
import LoadingLottie from '../assets/lotties/loading.json';

const LoattieLoading = () => {
    return (
        <Box flex={1} sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
        }} >
            <Box flex={1} sx={{
                position: "absolute",
                height: 200,
                width: 200
            }} >
                <Lottie
                    animationData={LoadingLottie}
                    loop={true}
                    autoplay={true}
                />
            </Box>
        </Box>
    );
};

export default LoattieLoading;