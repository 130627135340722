import Loadable from "../../components/Loadable";
import { lazy } from "react";

const NotFound = Loadable(lazy(() => import("./NotFound")));
const Verification = Loadable(lazy(() => import("./verification/Verify")));
const Register = Loadable(lazy(() => import("./register/Register")));
const ForgotPass = Loadable(lazy(() => import("./forgot-pass/ForgotPass")));
const Track = Loadable(lazy(() => import("../tracking/Track")));

const frontRoutes = [
    {
        path: "/verification",
        element: <Verification />,
    },
    {
        path: "/resetpass",
        element: <ForgotPass />,
    },
    {
        path: "/register",
        element: <Register />,
    },
    {
        path: "/track",
        element: <Track />,
    },
    {
        path: "/page/404",
        element: <NotFound />,
    }
];

export default frontRoutes;
