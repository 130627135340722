const colors = {
    TCG_BLUE: "#264796",
    TCG_GREEN: "#a0af14",
    TCG_WHITE: "#ffffff",
    TCG_GRAY: "##f0f0f2",
    TCG_BLACK: "#070b2b",
    TCG_RED: "#d32f2f",
    TCG_YELLOW: "#e6b437",
    TCG_ORANGE: "#ed7d31",
    TCG_DARK_GRAY: "#9C9C9C",
};

export default colors;
