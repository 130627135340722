import React from 'react';
import { getSettings, uploadLogo, getApiKeys, createNewApiKey, deleteApiKey } from 'infra/services/api';

const SettingsLogic = () => {
    return {
        getSettings: async (account_id) => {
            const response = await getSettings(account_id);
            return response;
        },
        getApiKeys: async (account_id) => {
            const response = await getApiKeys(account_id);
            return response;
        },
        createNewApiKey: async (payload) => {
            const response = await createNewApiKey(payload);
            return response;
        },
        deleteApiKey: async (id) => {
            const response = await deleteApiKey(id);
            return response;
        },
        uploadLogo: async (payload) => {
            const response = await uploadLogo(payload);
            return response
        }
    }
};

export default SettingsLogic;