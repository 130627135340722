import { API_URL } from 'config.js';
import { get } from './helpers';

export const get_general_data_filters = async () => {
    const response = await get(`${API_URL}/general-data`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};
