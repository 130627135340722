import { SET_IS_LOGIN } from "../types/types";

const initialState = {
    session: null,
};

const authReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_LOGIN:
            return { ...state, session: action.payload };
        default:
            return state;
    }
};

export default authReducers;
