import {
    SET_MAPS_DRIVERS,
    SET_MAPS_BRANCHES,
    SET_MAPS_RATES,
    SET_MAPS_SURCHARGES,
    SET_MAPS_SERVCES_DAYS,
    SET_MAPS_DRAFT,
} from "../types/types";

const initialState = {
    maps_branches: [],
    maps_drivers: [],
    maps_rates: [],
    maps_surharges: [],
    maps_service_days: [],
    maps_draft: [],
};

const mapsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MAPS_DRIVERS:
            return { ...state, maps_drivers: action.payload };
        case SET_MAPS_BRANCHES:
            return { ...state, maps_branches: action.payload };
        case SET_MAPS_RATES:
            return { ...state, maps_rates: action.payload };
        case SET_MAPS_SURCHARGES:
            return { ...state, maps_surharges: action.payload };
        case SET_MAPS_SERVCES_DAYS:
            return { ...state, maps_service_days: action.payload };
        case SET_MAPS_DRAFT:
            return { ...state, maps_draft: action.payload };
        default:
            return state;
    }
};

export default mapsReducer;
