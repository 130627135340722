import React from "react";
import routes from "./routes";
import { useRoutes } from "react-router-dom";
import { store, persistedStore } from "core/redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { GeneralDataProvider } from "./context/GeneralDataContext";
import { useGeneraldata } from "./context/GeneralDataContext";
import LoattieLoading from "./components/LoattieLoading";
import { Box } from "@mui/material";

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistedStore} loading={null}>
                <GeneralDataProvider>
                    <Content />
                </GeneralDataProvider>
            </PersistGate>
        </Provider>
    );
};

const Content = () => {
    const content = useRoutes(routes);
    const { is_fetching_general_data } = useGeneraldata();
    if (is_fetching_general_data) {
        return (
            <Box
                flex={1}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <LoattieLoading />
            </Box>
        );
    }
    return <React.Fragment>{content}</React.Fragment>;
};

export default App;
