import Redirect from "app/components/Redirect";
import NotFound from "./views/front-pages/NotFound";
import frontRoutes from "app/views/front-pages/frontRoutes";
import Loadable from "./components/Loadable";
import React, { lazy } from "react";
const RAadmin = Loadable(lazy(() => import("./views/RAadmin")));

const raAdminRoutes = [
    {
        path: "/*",
        element: <RAadmin />,
    },
];

const routes = [
    ...frontRoutes,
    ...raAdminRoutes,
    { path: "/", element: <Redirect /> },
    { path: "*", element: <NotFound /> },
];

export default routes;
