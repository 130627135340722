import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider } from "@mui/styled-engine";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StyledEngineProvider>
    <SnackbarProvider
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <BrowserRouter basename="/">

        <App />

      </BrowserRouter>
    </SnackbarProvider>
  </StyledEngineProvider>
);
